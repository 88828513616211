<template>
  <carousel-3d :width="width" :height="height" :controls-visible="true" :autoplay="true" :autoplayTimeout="5000">
    <slide v-for="(poster, index) in posters" :index="index" :key="poster.src" class="slide-ctn">
      <img :src="getImgUrl(poster.src)" alt=""/>
      <b-btn v-if="!disableButton" variant="info" @click="$router.push(`/fotos/${poster.name}`)" target="_blank">Ver fotos</b-btn>
    </slide>
  </carousel-3d>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  components: {
    Carousel3d,
    Slide
  },
  props: {
    posters: {
      type: Array,
      required: true
    },
    requireCtx: {
      type: Function,
      required: false,
      default: require.context('../assets/', false, /\.jpeg$/)
    },
    requireExtension: {
      type: String,
      required: false,
      default: 'jpeg'
    },
    width: {
      type: Number,
      required: false,
      default: 600
    },
    height: {
      type: Number,
      required: false,
      default: 771
    },
    disableButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getImgUrl(src) {
      var images = this.requireCtx
      return images('./' + src)
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/global.scss';

  .prev, .next {
    font-size: 130px !important;
    color: white !important;
  }

  .slide-ctn {
    .btn {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }

  .btn {
  border-radius: 100px;
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  border: 0px;
  padding: 1rem 2rem;
  box-shadow: 0px 0px 6px rgba(255,255,255,0.7);

  &:hover {
    background-color: $red;
    color: white;
    transition: all 0.3s ease-in-out;
  }
  }
</style>